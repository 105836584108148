export default function config() {
  // Table Handlers
  const tableColumns = [
    {
      key: 'actions',
      label: '',
    },
    {
      key: 'available',
      label: 'Available',
      // thStyle: { textAlign: 'center' },
    },
    {
      key: 'in',
      label: 'In',
      // thStyle: { width: '1%' },
    },
    {
      key: 'other_loc',
      label: 'Other Loc.',
      // thStyle: { width: '1%' },
    },
    {
      key: 'reserved',
      label: 'Reserved',
      // thStyle: { width: '0%' },
    },
    {
      key: 'out',
      label: 'Out',
      // thStyle: { width: '0%' },
    },
    {
      key: 't_in',
      label: 'T. In',
      // thStyle: { width: '1%' },
    },
    {
      key: 't_out',
      label: 'T. Out',
      // thStyle: { width: '1%' },
    },
    {
      key: 'repair',
      label: 'Repair',
      // thStyle: { width: '0%' },
    },
    {
      key: 'on_order-po',
      label: 'On Order-PO',
      // thStyle: { width: '2%' },
    },
    {
      key: 'unavailable',
      label: 'Unavailable',
      // thStyle: { width: '5%' },
    },
  ]

  return {
    tableColumns,
  }
}
