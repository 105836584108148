<template>
  <div style="display: none">
    <b-modal
      id="show-request-documentation-modal"
      ref="show-request-documentation-modal"
      centered
      hide-header
      body-class="bs-warning-modal__body modal-bg-color"
      footer-class="show-contact-types-modal__footer justify-content-between border-top-0 modal-bg-color pb-2"
      ok-variant="success"
      cancel-variant="outline-primary"
      size="lg"
      :ok-disabled="checkedId"
      @ok="onOk"
      @hidden="resetModalForm"
    >
      <inventory-quote-modal-skeleton v-if="isLoading" />
      <div
        v-else
        class="mt-1"
      >
        <b-row>
          <b-col cols="12">
            <h3 class="l-modal__title">
              {{ $t('Request Documentation') }}
            </h3>
            <p class="text-center">
              {{ $t('Please select the one of existing Rental Order drafts') }}
            </p>
            <l-table
              ref="lTableModalRef"
              :table-config-options="getTableConfigOptions()"
              :module-name="MODULE_NAME"
              :is-pagination="true"
              :table-columns="addToExistingQuoteModelTable"

              :is-searchable="false"
              :is-pagination-available="true"
            >
              <template #cell(radio)="{ data: { item } }">
                <b-form-radio
                  v-model="checkedId"
                  name="MODULE_NAME"
                  :value="item.id"
                />
              </template>
              <template #cell(order_id)="{ data: { item } }">
                {{ item.order_id }}
              </template>
              <template #cell(customer_id)="{ data: { item } }">
                {{ item.customer_id }}
              </template>
            </l-table>
          </b-col>
        </b-row>
      </div>
    </b-modal>

  </div>
</template>

<script>
import {
  BCol, BRow, BFormRadio, BModal,
} from 'bootstrap-vue'
import LTable from '@/views/components/LTable/LTable.vue'
import InventoryQuoteModalSkeleton
from '@/views/main/inventory/inventory-calendar-product/components/inventoryQuoteModal/InventoryQuoteModalSkeleton.vue'
import config from '@/views/main/inventory/inventoryConfig'

export default {
  name: 'InventoryQuoteModal',
  components: {
    BModal,

    InventoryQuoteModalSkeleton,
    BFormRadio,
    BCol,
    LTable,
    BRow,
  },
  data() {
    return {
      isLoading: false,
      checkedId: '',
    }
  },
  computed: {
    inventories() {
      return this.$store.state[this.PRODUCT_MODULE_NAME].product
    },
  },
  methods: {
    getTableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getListAddToExistingQuote`,
        data: {
          product: this.$store.state[this.MODULE_NAME].product.id,
        },
      }
    },
    resetModalForm() {
    },
    showModal() {
      this.$bvModal.show('inventory-quote-modal')
    },
    onOk() {
      this.isSendingEmail = true
      const data = {
        order_id: this.checkedId,
      }
      this.$router.push({
        name: 'draft-quote-information',
        query: {
          id: data.order_id,
          product_id: `${this.inventories.id}`,
          is_rental: this.inventories.detail.can_be_rented_individually,
          is_sale: this.inventories.detail.can_be_sold,
        },
      })
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const PRODUCT_MODULE_NAME = 'inventories'
    const { addToExistingQuoteModelTable } = config()

    return {
      MODULE_NAME,
      PRODUCT_MODULE_NAME,
      addToExistingQuoteModelTable,
    }
  },
}
</script>
<style scoped lang="scss">
#bs-warning-modal {
  .modal-dialog {
    max-width: 500px !important;
  }
}
</style>
