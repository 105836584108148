<template>
  <div>
    <div
      class="d-flex justify-content-center flex-md-wrap-reverse"
      style="margin-bottom: 7px"
    >
      <b-skeleton
        width="70%"
        height="24px"
        class="mb-1"
      /><b-skeleton
        width="60%"
        height="24px"
        class="mb-1"
      /><b-skeleton
        width="90%"
        height="24px"
        class="mb-1"
      />
    </div>
    <b-card
      class="l-calendar__card py-2"
      no-body
    >
      Loading...
    </b-card>
  </div>
</template>

<script>
import {
  BSkeleton, BCard,
} from 'bootstrap-vue'

export default {
  name: 'InventoryQuoteModalSkeleton',
  components: {
    BCard,
    BSkeleton,
  },

}
</script>
<style lang="scss">
.l-calendar--skeleton{
  height: 280px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__container{
    display: inline-grid;
    grid-template-columns: auto auto auto auto auto;
  }
  &__box{
    width: 67px;
    height: 56px;
  }
}
</style>
