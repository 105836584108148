<template>
  <div class="d-flex mt-2 pb-1 justify-content-between">
    <div>
      <b-button
        class="cancelBtn font-medium-1 font-weight-bolder"
        variant="outline-primary"
        @click="back"
      >
        {{ $t(backButtonTitle) }}
      </b-button>
    </div>
    <div
      class="d-flex"
      style="gap: 12px"
    >
      <b-button @click="showModal('show-request-documentation-modal')">
        {{ $t('Add to existing Quote') }}
      </b-button>
      <b-button @click="redirectToQuote">
        {{ $t('Add to a new Quote') }}
      </b-button>
    </div>
    <inventory-quote-modal />
  </div>
</template>
<script>
import {
  BButton,
} from 'bootstrap-vue'
import config from '@/views/main/inventory/inventory-calendar-product/components/inventoryCalendarConfig'
import InventoryQuoteModal
from '@/views/main/inventory/inventory-calendar-product/components/inventoryQuoteModal/InventoryQuoteModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'InventoryTableBottom',
  components: {
    InventoryQuoteModal,
    BButton,
  },
  data() {
    return {
      isSendingEmail: false,
      checkedIds: [],
      // theProductId: this.$route.params?.id,
      checkedItems: {
        checkedIds: [],
        message: '',
      },
    }
  },
  computed: {
    isSwitchToCalendarTable() {
      return this.$store.state[this.MODULE_NAME].isSwitchToCalendarTable
    },
    inventories() {
      return this.$store.state[this.PRODUCT_MODULE_NAME].product
    },
    backButtonTitle() {
      return this.isSwitchToCalendarTable ? 'Back to Calendar List' : 'Back to List'
    },
    canBeRentedIndividually() {
      return this.$store.state[this.PRODUCT_MODULE_NAME].product.detail.can_be_rented_individually
    },
  },
  methods: {
    redirectToQuote() {
      const query = {
        product_id: `${this.inventories.id}`,
        is_rental: this.inventories.detail.can_be_rented_individually,
        is_sale: this.inventories.detail.can_be_sold,
      }
      this.$router.push({ name: 'home-orders-rental-sales-create', query })
    },
    showModal(modalName) {
      this.$bvModal.show(modalName)
    },
    back() {
      if (this.isSwitchToCalendarTable) {
        this.switchToCalendarTable(false)
        this.allStatesDisBtn(true)
      } else {
        this.$store.commit(`${this.PRODUCT_MODULE_NAME}/GET_PRODUCT`, {})
        this.$router.push({ name: 'home-inventory' })
      }
      this.$emit('back')
    },
    addToExistingQuote() {
      this.$emit('addToExistingQuote')
    },
    switchToCalendarTable(bool) {
      this.$store.commit(`${this.MODULE_NAME}/SWITCH_TO_CALENDAR_TABLE`, bool)
    },
    allStatesDisBtn(bool) {
      this.$store.commit(`${this.MODULE_NAME}/ALL_STATES_DIS_BTN`, bool)
    },
    orderPurchases() {
      this.$store
        .dispatch(`${this.MODULE_NAME}/getOrderPurchasesList`)
    },
  },
  setup() {
    const MODULE_NAME = 'allocations'
    const PRODUCT_MODULE_NAME = 'inventories'
    const { tableStockColumns } = config()
    return {
      tableStockColumns,
      MODULE_NAME,
      PRODUCT_MODULE_NAME,
    }
  },
}
</script>
