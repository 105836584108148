<template>
  <b-th>
    <span
      :id="`${countTitle+countTitleKey}`"
      class="parentToolTip last-cell"
    >
      {{ isCountTable() }}
    </span>
    <b-tooltip
      v-if="toolTipData()"
      :target="`${countTitle+countTitleKey}`"
      triggers="click"
      :html="true"
      placement="auto"
    >
      <p class="info-title">
        {{ isPopupInfo.title }}
      </p>
      <div class="d-flex">
        <div class="info-continer-left">
          <p>Account</p>
          <p>Status</p>
          <p>From</p>
          <p>To</p>
        </div>
        <div class="info-continer-right">
          <p>{{ isPopupInfo.account }}</p>
          <p>{{ isPopupInfo.status }}</p>
          <p>{{ isPopupInfo.from }}</p>
          <p>{{ isPopupInfo.to }}</p>
        </div>
      </div>
    </b-tooltip>
  </b-th>
</template>
<script>

import { BTh, BTooltip } from 'bootstrap-vue'

export default {
  name: 'ToolTipCalendar',
  components: { BTh, BTooltip },
  props: {
    isPopup: {
      type: Array || Object,
      required: false,
    },
    countTitleKey: {
      type: String,
      required: false,
    },
    countTitle: {
      type: String,
      required: false,
    },
    isCount: {
      type: Number,
      required: true,
    },
    countClass: {
      type: String,
      required: false,
    },
  },
  computed: {
    isPopupInfo() {
      return this.isPopup
    },
  },
  methods: {
    isCountTable() {
      return this.isCount
    },
    toolTipData() {
      const isPopupLength = Object.entries(this.isPopup).length
      return isPopupLength > 0 ? this.isPopup : null
    },
  },
  setup() {
    return {

    }
  },
}
</script>
<style scoped>
.parentToolTip{
  cursor: pointer;
  position: relative !important;
  padding: 0;
  justify-content: center;
}
.tooltip-inner{
  max-width: 340px;
  width:502px;
  height: 178px;
  background: #000000B2;
  opacity: 1;
  padding: 20px;

}
.info-title{
  font-size: 22px;
  text-align: start;
  color: #ffffff;
}
.info-continer-left{
  width: 30%;
  text-align: start;
  font-size: 16px;
  p{
    margin-bottom: 0.5rem;
  }
}
.info-continer-right{
  text-align: start;
  width: 70%;
  font-size: 16px;
  p{
    margin-bottom: 0.5rem;
  }
}
</style>
