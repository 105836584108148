<template>
  <div
    class="d-flex align-items-center justify-content-end"
  >
    <b-dropdown
      size="md"
      no-caret
      variant="customClassButton"
      class="paddingCustom"
    >
      <template
        #button-content
      >
        <div class="dropNewClass">
          <span>
            {{ selectedWarehouse.label }}
          </span>
          <feather-icon
            icon="LArrowDownIcon"
            size="11"
            class="lightIcon m-0"
          />
        </div>
      </template>
      <div
        v-for="(item, index) in warehouseList"
        :key="index"
      >
        <b-dropdown-item
          v-if="item.status === true || item.status == null"
          @click="setWarehouseId(item.id)"
        >
          Switch to {{ item.label }}
        </b-dropdown-item>
      </div>

    </b-dropdown>
    <button-dashed
      class="inventory-calendar-table-options printBtn"
      @click="printCanvas"
    >
      <feather-icon
        icon="LTransferIcon"
        size="18"
      />
      {{ $t('Print') }}
    </button-dashed>
    <button-dashed
      class=" inventory-calendar-table-options"
      @click="gotoNewPage({name: 'home-inventory-catalog-item-audit', params: {id: routeID}}, $event)"
    >
      <feather-icon
        icon="LHandsIcon"
        size="18"
      />
    </button-dashed>
    <button-dashed class=" inventory-calendar-table-options">
      <feather-icon
        icon="LSignalIcon"
        size="16"
      />
    </button-dashed>
    <button-dashed
      class="inventory-calendar-table-options"
      @click="navigateToViewInventory"
    >
      <feather-icon
        icon="LEyeIcon"
        size="18"
      />
    </button-dashed>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import store from '@/store'
import calendar from '@/store/calendar'
// eslint-disable-next-line import/no-extraneous-dependencies
import html2canvas from 'html2canvas'

export default {
  name: 'Options',
  components: {
    ButtonDashed,
    BDropdown,
    BDropdownItem,
  },
  props: {
    reFetch: {
      type: Function,
      required: true,
    },

  },
  data() {
    return {
      routeID: this.$route.params.id,
      allWareHouse: {
        label: 'All Warehouses',
        id: NaN,
      },
      printContent: '',
    }
  },
  computed: {
    product() {
      return this.$store.state[this.PRODUCT_MODULE_NAME].product
    },
    warehouseList() {
      const { selectedWarehouseId } = this.$store.state[this.CALENDAR_MODULE_NAME]
      const warehouses = this.$store.state.horizontalMenu.warehouseLocations
      const warehouseForUi = [...warehouses.filter(i => i.id !== selectedWarehouseId && i.status), this.allWareHouse]
      return selectedWarehouseId ? warehouseForUi : warehouses
    },
    selectedWarehouse() {
      const warehouses = this.$store.state.horizontalMenu.warehouseLocations
      const { selectedWarehouseId } = this.$store.state[this.CALENDAR_MODULE_NAME]
      const foundWarehouse = warehouses.find(item => item.id === selectedWarehouseId)
      return foundWarehouse || this.allWareHouse
    },
  },
  mounted() {
    const currentWareHouseId = this.$store.state.app.warehouse.id
    this.setWarehouseId(currentWareHouseId)
  },
  methods: {
    printCanvas() {
      const appContentEl = document.getElementById('app-content')

      html2canvas(appContentEl).then(canvas => {
        const printWindow = window.open('', '_blank')
        const printDocument = printWindow.document

        printDocument.write(`
        <html>
          <head>
            <title>Print Canvas</title>
            <style>
              body { margin: 0;width: 100%;height: 100%; }
              canvas { display: block; width: 100%;height: 100%;}
            </style>
          </head>
          <body>
            <canvas id="printCanvas" style="display:block;"></canvas>
          </body>
        </html>
      `)

        const printCanvas = printDocument.getElementById('printCanvas')
        printCanvas.width = canvas.width
        printCanvas.height = canvas.height

        const printCtx = printCanvas.getContext('2d')
        printCtx.drawImage(canvas, 0, 0)

        printWindow.print()
        printWindow.close()
      })
    },
    setWarehouseId(id) {
      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/SET_WAREHOUSE_ID`, id)
      this.reFetch()
    },
    navigateToViewInventory() {
      const warehouseId = this.product.created_by.current_warehouse.id
      this.$router.push({ name: 'home-inventory-view', params: { id: this.routeID }, query: { location: `${warehouseId}` } })
    },
  },
  setup() {
    const CALENDAR_MODULE_NAME = 'calendar'
    const PRODUCT_MODULE_NAME = 'inventories'

    if (!store.hasModule(CALENDAR_MODULE_NAME)) store.registerModule(CALENDAR_MODULE_NAME, calendar)

    return {
      PRODUCT_MODULE_NAME,
      CALENDAR_MODULE_NAME,
    }
  },
}
</script>
<style lang="scss" >
.paddingCustom {
  border: 1px dashed #dde3e7;
  border-radius: 0.35rem;
  height: 36px;
  min-width: 115px;
  margin-right: 9px;

  & .btn {
    padding: 7px 15.5px !important;
  }

  .dropdown-item {
    padding: 0.2rem 1.28rem;
  }
}

.dropNewClass{
  outline: none;
  display: flex;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 16px !important;
  color: #49454f;
  gap: 9px;
}
.inventory-calendar-table-options {
  //width: 36px;
  height: 36px;
  padding: 0 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  //& svg {
  //  fill: #ADADAD;
  //}
}
.printBtn{
  display: flex;
  justify-content: center;
  gap: 9px;
}
</style>
