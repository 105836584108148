<template>
  <b-row class="justify-content-between mb-2">
    <b-col
      cols="10"
      class="d-flex"
    >
      <b-button
        v-for="[key,value] in filtersAllocations"
        :key="value.title"
        class="mr-1 buttonContent"
        :variant="isActiveFilterButton(key) ? '' : 'secondary-light'"
        @click="changeFilter(key)"
      >
        <div
          v-if="value.icon"
          :class="isActiveFilterButton(key) ? 'isActive' : 'filterSvg'"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          ><path
            :d="value.icon"
          /></svg>
        </div>
        {{ value.title ? value.title : '---' }}

      </b-button>
    </b-col>
    <b-col
      cols="2"
      class="d-flex"
    >
      <b-button
        style="min-width: 130px;"
        variant="success"
        class="ml-auto"

        @click="switchToCalendarTable(true)"
      >
        {{ $t('All states') }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>

import {
  BButton, BCol, BRow, VBTooltip,
} from 'bootstrap-vue'
import config from '@/views/main/inventory/inventoryConfig'

export default {
  name: 'InventoryFilterTabs',
  components: {
    BButton, BCol, BRow,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isFetching: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      currentKey: this.typeOfInfo.AVAILABLE,
    }
  },
  computed: {
    filtersAllocations() {
      const { allocation } = this.$store.state[this.MODULE_NAME_ALLOCATIONS]
      return allocation?.enums ? Object.entries(allocation?.enums) : []
    },
  },
  methods: {
    isActiveFilterButton(key) {
      const selectedFilter = this.$store.state[this.MODULE_NAME_ALLOCATIONS].buttonFilters
      return selectedFilter === key
    },
    switchToCalendarTable(bool) {
      this.$emit('switchToCalendar')
      this.$store.commit(`${this.MODULE_NAME_ALLOCATIONS}/SWITCH_TO_CALENDAR_TABLE`, bool)
    },
    changeFilter(key) {
      this.$store.commit(`${this.MODULE_NAME_ALLOCATIONS}/CHANGE_FILTER`, key)
    },
  },
  setup() {
    const MODULE_NAME_ALLOCATIONS = 'allocations'
    const {
      typeOfInfo,
    } = config()

    return {
      typeOfInfo,
      MODULE_NAME_ALLOCATIONS,
    }
  },

}
</script>

<style lang="scss" scoped>
   .buttonContent{
     display: flex;
     justify-content: center;
     align-items: center;
     gap: 8px;
   }

   .isActive{
     svg{
       path{
         fill: #fff;
       }
     }
   }

   .filterSvg{
     svg{
       path{
         fill: #646464;
       }
     }
   }

</style>
