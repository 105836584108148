<template>
  <div>
    <inventory-table-skeleton v-if="isLoading" />
    <div v-else>
      <inventory-product-name />
      <inventory-read-only-data class="my-1" />
      <options
        class="mb-1"
        :re-fetch="fetchData"
      />
      <inventory-filter-tabs
        v-show="!isSwitchToCalendarTable"
        :is-fetching="isFetching"
        @switchToCalendar="fetchDataCalendarDay"
      />
      <div>
        <calendar-table-all-assets
          v-if="isSwitchToCalendarTable"
          :is-fetching-day-calendar="isFetchingDayCalendar"
        />
        <inventory-calendar
          v-else
          :ref="'inventoryCalendar'"
          :is-fetching="isFetching"
          :months="currentMonth"
          :year="currentYear"
          :module-name="MODULE_NAME"
        />
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <inventory-date-controller
          :ref="'inventoryDateController'"
          :prev-month="prevMonth"
          :next-month="nextMonth"
          :prev-week="prevWeek"
          :next-week="nextWeek"
          :prev-day="prevDay"
          :next-day="nextDay"
          :set-current-date="setCurrentDate"
          :is-fetching="isFetching"
          :change-date="changeDate"
          :is-this-day="isThisDay"
          :is-this-day-for-day="isThisDayforDay"
          :selected-date="selectedDate"
        />
      </div>
      <portal to="body-footer">
        <inventory-table-bottom
          @back="switchToMouthCalendar()"
          @addToExistingQuote="showConfirmationModal()"
        />
      </portal>
    </div>
  </div>
</template>
<script>
import * as dateFnsOptions from 'date-fns'
import { isSameDay, startOfMonth } from 'date-fns'
import Options from './components/Options.vue'
import InventoryCalendar from './components/InventoryCalendar.vue'
import InventoryFilterTabs from './components/InventoryFilterTabs.vue'
import InventoryTableBottom from './components/InventoryTableBottom.vue'
import InventoryProductName from './components/InventoryProductName.vue'
import InventoryReadOnlyData from './components/InventoryReadOnlyData.vue'
import InventoryTableSkeleton from './components/InventoryTableSkeleton.vue'
import InventoryDateController from './components/InventoryDateController.vue'
import CalendarTableAllAssets from './components/calendarTableAllAssets/CalendarTableAllAssets.vue'

export default {
  name: 'InventoryTable',
  components: {
    Options,
    InventoryCalendar,
    InventoryFilterTabs,
    InventoryTableBottom,
    InventoryProductName,
    InventoryReadOnlyData,
    CalendarTableAllAssets,
    InventoryTableSkeleton,
    InventoryDateController,
  },
  data() {
    return {
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      isFetching: false,
      isFetchingDayCalendar: false,
      isLoading: false,
    }
  },
  computed: {

    selectedDate() {
      return this.$store.state[this.CALENDAR_MODULE_NAME].selectedDate
    },

    isSwitchToCalendarTable() {
      return this.$store.state[this.MODULE_NAME].isSwitchToCalendarTable
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    isThisDay() {
      const { date } = this.$store.state[this.CALENDAR_MODULE_NAME]
      return date.some(i => isSameDay(i, new Date()))
    },
    isThisDayforDay() {
      const { selectedDate } = this.$store.state[this.CALENDAR_MODULE_NAME]
      return isSameDay(selectedDate, new Date())
    },

    calendarDate() {
      const startDateOfMonth = dateFnsOptions.startOfMonth(new Date())
      const endDateOfMonth = dateFnsOptions.endOfMonth(new Date())
      const prevMonth = dateFnsOptions.lastDayOfMonth(dateFnsOptions.subMonths(startDateOfMonth, 1))

      const indexOfMonthFirstDate = dateFnsOptions.getDay(startDateOfMonth)

      const startDateOfPrevMonth = dateFnsOptions.subDays(prevMonth, indexOfMonthFirstDate === 0 ? 0 : indexOfMonthFirstDate - 1)
      const nextMoth = dateFnsOptions.addDays(endDateOfMonth, 6)

      const startDay = this.$store.state[this.CALENDAR_MODULE_NAME].date?.[0] || startDateOfPrevMonth
      const lastDays = this.$store.state[this.CALENDAR_MODULE_NAME].date.length - 1 || 42
      const lastDay = this.$store.state[this.CALENDAR_MODULE_NAME].date?.[lastDays] || nextMoth

      const dateFrom = dateFnsOptions.format(startDay, 'yyyy-MM-dd')
      const dateTo = dateFnsOptions.format(lastDay, 'yyyy-MM-dd')
      return { dateFrom, dateTo }
    },

  },
  created() {
    this.isLoading = true

    this.$store.commit(`${this.PRODUCT_MODULE_NAME}/GET_PRODUCT`, {})

    // Getting Inventory(Product)
    this.$store.dispatch(`${this.PRODUCT_MODULE_NAME}/getStockProduct`, { id: this.$route.params.id }).then(res => {
      const { data } = res.data

      this.$store.commit(`${this.PRODUCT_MODULE_NAME}/GET_PRODUCT`, data)
    }).finally(() => {
      this.isLoading = false
    })
  },
  methods: {
    prevMonth() {
      this.$refs.inventoryCalendar.prevMonth()
      this.fetchDataMath()
    },
    nextMonth() {
      this.$refs.inventoryCalendar.nextMonth()
      this.fetchDataMath()
    },
    prevWeek() {
      this.$refs.inventoryCalendar.prevWeek()
      this.fetchDataMath()
    },
    nextWeek() {
      this.$refs.inventoryCalendar.nextWeek()
      this.fetchDataMath()
    },
    prevDay() {
      const result = dateFnsOptions.subDays(this.selectedDate, 1)
      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/SET_SELECTED_DATE`, result)
      this.$refs.inventoryDateController.$refs.lDataPicker.$refs.flatPicker.fp.setDate(this.selectedDate)
      this.fetchDataCalendarDay()
    },
    nextDay() {
      const result = dateFnsOptions.subDays(this.selectedDate, -1)
      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/SET_SELECTED_DATE`, result)
      this.$refs.inventoryDateController.$refs.lDataPicker.$refs.flatPicker.fp.setDate(this.selectedDate)
      this.fetchDataCalendarDay()
    },
    setCurrentDate() {
      this.$refs.inventoryDateController.$refs.lDataPicker.$refs.flatPicker.fp.setDate(new Date())
      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/SET_SELECTED_DATE`, new Date())
      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/START_DATE_OF_MONTH`, startOfMonth(new Date()))
      if (this.isSwitchToCalendarTable) {
        this.fetchDataCalendarDay()
      } else {
        this.$refs.inventoryCalendar.allDateOfCurrentMonth()
        this.fetchDataMath()
      }
      // this.fetchDataMath() // Todo i think we dont need this fetching bc we already fetch in operations
    },
    changeDate(newDate) {
      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/SET_SELECTED_DATE`, newDate)
      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/START_DATE_OF_MONTH`, startOfMonth(newDate))
      if (this.isSwitchToCalendarTable) {
        this.fetchDataCalendarDay()
      } else {
        this.$refs.inventoryCalendar.allDateOfCurrentMonth(newDate)
        this.fetchDataMath()
      }
    },

    fetchData() {
      if (!this.isSwitchToCalendarTable) {
        this.fetchDataMath()
      } else {
        this.fetchDataCalendarDay()
      }
    },

    fetchDataMath() {
      const { selectedWarehouseId } = this.$store.state[this.CALENDAR_MODULE_NAME]
      this.isFetching = true
      this.$store.dispatch(`${this.MODULE_NAME}/get`, {
        product_id: this.$route.params.id,
        date_from: `${this.calendarDate.dateFrom}`,
        date_to: `${this.calendarDate.dateTo}`,
        warehouse_id: selectedWarehouseId || null,
      }).finally(() => {
        this.isFetching = false
      })
    },
    fetchDataCalendarDay() {
      const { selectedWarehouseId } = this.$store.state[this.CALENDAR_MODULE_NAME]
      const selectDay = dateFnsOptions.format(this.selectedDate, 'yyyy-MM-dd') ?? dateFnsOptions.format(new Date(), 'yyyy-MM-dd')
      this.isFetchingDayCalendar = true
      this.$store.dispatch(`${this.MODULE_NAME}/getCalendarDay`, {
        product_id: this.$route.params.id,
        day: selectDay,
        warehouse_id: selectedWarehouseId || null,
      }).finally(() => {
        this.isFetchingDayCalendar = false
      })
    },
    switchToMouthCalendar() {
      setTimeout(() => {
        this.$refs.inventoryCalendar.allDateOfCurrentMonth(this.selectedDate)
        this.fetchDataMath()
      }, 0)
    },
    back() {
      this.$store.commit(`${this.PRODUCT_MODULE_NAME}/GET_PRODUCT`, {})
      this.$router.push({ name: 'home-inventory' })
    },
  },
  setup() {
    const MODULE_NAME = 'allocations'
    const PRODUCT_MODULE_NAME = 'inventories'
    const CALENDAR_MODULE_NAME = 'calendar'

    return {
      MODULE_NAME,
      PRODUCT_MODULE_NAME,
      CALENDAR_MODULE_NAME,
    }
  },

}
</script>
