export default function config() {
  const tableColumnsOfWeek = [
    {
      label: 'Sun',
    },
    {
      label: 'Mon',
    },
    {
      label: 'Tue',
    },
    {
      label: 'Wed',
    },
    {
      label: 'Thu',
    },
    {
      label: 'Fri',
    },
    {
      label: 'Sat',
    },
  ]
  const tableStockColumns = [
    {
      key: 'batchdelete',
      label: '',
      thStyle: { width: '10px' },
    },
    {
      key: 'calendarIcon',
      label: '',
      thStyle: { width: '92px' },
    },
    {
      key: 'sku',
      label: 'SKU',
      thStyle: { width: '160px' },
    },
    {
      key: 'icon',
      label: '',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'brand',
      label: 'Brand',
    },
    {
      key: 'model',
      label: 'Model',
      thStyle: { width: '200px' },
    },
    {
      key: 'category',
      label: 'Category',
    },
    {
      key: 'is_active',
      label: 'Status',
      thStyle: { width: '100px' },
    },
  ]
  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const dateFormats = 'MMM dd'

  return {
    tableColumnsOfWeek,
    tableStockColumns,
    weekDays,
    dateFormats,
  }
}
