<template>
  <b-overlay :show="isFetching">
    <b-card
      class="l-calendar__card"
      no-body
    >
      <BRow class="mx-0">
        <BCol
          v-for="label in weekDays"
          :key="label"
          class="px-0 l-calendar__card-table-head"
        >
          <div class="l-calendar__card-table-head-cell">
            {{ label }}
          </div>
        </BCol>
      </BRow>
      <div
        id="v-for-object"
        class="parent l-calendar__card-table"
      >
        <div
          v-for="(item, index) in dataCalendarMerge"
          :key="index"
          :class="`l-calendar__card-table-body-cell body-cell px-0 border ${isWeekend(item.date)}`"
        >
          <div>
            <div class="d-flex justify-content-end">
              <div :class="`itemDate ${isFirstDayOfMonth(item.date) || isDateEqual(item.date) ? isDateEqual(item.date) ? 'date' : 'firstDayOfMonth' : ''}`">
                {{ formatDate(item.date) }}
              </div>
            </div>
            <div class="d-flex justify-content-center itemCount">
              {{ item.filterCount }}
            </div>
            <div class="body-cell__middle d-flex justify-content-center align-items-center" />
          </div>
        </div>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol,
} from 'bootstrap-vue'
import config from '@/views/main/inventory/inventoryConfig'
import * as dateFnsOptions from 'date-fns'
import { isNumber } from '@vueuse/core'
import inventoryCalendarConfig from './inventoryCalendarConfig'

export default {
  name: 'InventoryCalendar',
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
  },
  props: {
    isFetching: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      currentKey: this.typeOfInfo.AVAILABLE,
    }
  },
  computed: {
    allocations() {
      return this.$store.state[this.MODULE_NAME].allocation
    },
    startOfMonth() {
      return this.$store.state[this.CALENDAR_MODULE_NAME].startOfMonth
    },
    selectedDate() {
      return this.$store.state[this.CALENDAR_MODULE_NAME].selectedDate
    },
    dataCalendarMerge() {
      const { date } = this.$store.state[this.CALENDAR_MODULE_NAME]
      const { allocation, buttonFilters } = this.$store.state[this.MODULE_NAME]
      const calendarDates = Object.values(date).map(i => ({
        date: i,
        filterCount: 0,
      }))
      const allocationsData = allocation?.data

      if (!allocationsData) return calendarDates

      const formattedObject = Object.entries(allocationsData).map(([key, value]) => ({ date: key, item: value }))
      const res = calendarDates.filter(i => formattedObject.some(bkItem => this.isSameDay(bkItem.date, i.date))).map(i => {
        const sameDay = formattedObject.find(k => this.isSameDay(k.date, i.date))
        return {
          ...i,
          filterCount: sameDay.item[buttonFilters],
        }
      })
      return res.length < 42 ? calendarDates : res
    },
    dataCalendar() {
      return this.$store.state[this.CALENDAR_MODULE_NAME].date
    },
    nextDateOfCurrentMonth() {
      return dateFnsOptions.addMonths(this.startOfMonth, 1)
    },
    prevDateOfCurrentMonth() {
      return dateFnsOptions.subMonths(this.startOfMonth, 1)
    },
  },
  created() {
    this.allDateOfCurrentMonth()
  },
  methods: {
    isSameDay(firstDate, secondDate) {
      return firstDate === dateFnsOptions.format(secondDate, 'yyyy-MM-dd')
    },
    formatDate(date) {
      return dateFnsOptions.format(date, this.dateFormats)
    },
    isWeekend(date) {
      if (this.isDateEqual(date)) {
        return 'selectedDate'
      } if (dateFnsOptions.isWeekend(date) || dateFnsOptions.isBefore(date, new Date())) {
        return 'box'
      }
      return ''
    },
    isDateEqual(date) {
      return dateFnsOptions.isSameDay(date, this.selectedDate)
    },
    isFirstDayOfMonth(date) {
      return dateFnsOptions.isFirstDayOfMonth(date)
    },
    allDateOfCurrentMonth(firstDateOfMonth = new Date()) {
      const startDateOfMonth = dateFnsOptions.startOfMonth(firstDateOfMonth)
      const endDateOfMonth = dateFnsOptions.endOfMonth(firstDateOfMonth)

      const prevMonth = dateFnsOptions.lastDayOfMonth(dateFnsOptions.subMonths(startDateOfMonth, 1))
      const datesOfMonth = dateFnsOptions.eachDayOfInterval({ start: startDateOfMonth, end: endDateOfMonth })

      const indexOfMonthFirstDate = dateFnsOptions.getDay(startDateOfMonth)

      const startDateOfPrevMonth = dateFnsOptions.subDays(prevMonth, indexOfMonthFirstDate === 0 ? 0 : indexOfMonthFirstDate - 1)

      const prevDatesOfMonth = dateFnsOptions.eachDayOfInterval({ start: startDateOfPrevMonth, end: prevMonth })

      const lastAndCurrentDates = [...prevDatesOfMonth, ...datesOfMonth]

      const currentLastDateOfMonth = dateFnsOptions.lastDayOfMonth(firstDateOfMonth)
      const nextMoth = dateFnsOptions.addDays(currentLastDateOfMonth, 42 - lastAndCurrentDates.length)
      const nextDatesOfMonth = dateFnsOptions.eachDayOfInterval({ start: dateFnsOptions.addDays(currentLastDateOfMonth, 1), end: nextMoth })

      const result = lastAndCurrentDates.concat(nextDatesOfMonth)

      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/SET_DATA`, result)
    },

    nextMonth() {
      this.allDateOfCurrentMonth(this.nextDateOfCurrentMonth)
      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/START_DATE_OF_MONTH`, this.nextDateOfCurrentMonth)
    },
    prevMonth() {
      this.allDateOfCurrentMonth(this.prevDateOfCurrentMonth)
      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/START_DATE_OF_MONTH`, this.prevDateOfCurrentMonth)
    },

    nextWeek() {
      const currenDates = this.dataCalendar
      const lastDate = currenDates[currenDates.length - 1]
      const nextEndDateWeek = dateFnsOptions.addDays(lastDate, 7)
      const nextWeekDates = dateFnsOptions.eachDayOfInterval({ start: dateFnsOptions.addDays(lastDate, 1), end: nextEndDateWeek })
      const removeFirstWeek = currenDates.splice(7)
      const result = removeFirstWeek.concat(nextWeekDates)
      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/SET_DATA`, result)
      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/START_DATE_OF_MONTH`, result[0])
    },
    prevWeek() {
      const currenDates = this.dataCalendar

      const firstDate = currenDates[0]

      const prevStartDateWeek = dateFnsOptions.subDays(firstDate, 7)

      const prevWeekDates = dateFnsOptions.eachDayOfInterval({ start: prevStartDateWeek, end: dateFnsOptions.subDays(firstDate, 1) })

      const result = dateFnsOptions.eachDayOfInterval({ start: prevWeekDates[0], end: dateFnsOptions.subDays(currenDates[35], 1) })

      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/SET_DATA`, result)
      this.$store.commit(`${this.CALENDAR_MODULE_NAME}/START_DATE_OF_MONTH`, result[0])
    },
  },
  setup() {
    const MODULE_NAME = 'allocations'
    const CALENDAR_MODULE_NAME = 'calendar'
    const { typeOfInfo } = config()
    const { weekDays, dateFormats } = inventoryCalendarConfig()

    return {
      MODULE_NAME,
      CALENDAR_MODULE_NAME,
      typeOfInfo,
      weekDays,
      dateFormats,
    }
  },
}
</script>
<style scoped lang="scss">
  .l-calendar__card {
    padding: 12px;
    border: 1px solid #DDE3E7;
    border-radius: 5px;

    &-table &-table-body:not(:last-child) &-table-body-cell{
      border-bottom: none !important;
    }

    &-table-head {
      &-cell {
        text-align: center;
        font-weight: bolder;
      }
    }

    &-table-body {

      &-cell {
        height: 72px;

        & .body-cell {
          &__top {
            padding: 0 8px !important;
            height: 24px;
          }

          &__middle {
            height: 48px;
            font-weight: bolder;
          }
        }
      }

    }

  }

  .parent {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    .box{
      background: #F6F6F6;
      .itemCount{
        color: #ADADAD;
      }
    }
    .selectedDate{
      background: #480B7326;
      .date{
        background: #490A73CC;
        color: #fff;
        width: auto;
      }
    }
    .itemDate{
      padding: 0 4px;
    }
    .itemCount{
      font-weight: 700;
      font-size: 20px;
      color: #646464;
    }
    .firstDayOfMonth{
      background: #ADADAD;
      color: #fff;
    }
  }
</style>
