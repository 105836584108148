<template>
  <portal to="body-top">
    <h4 class="text-center font-weight-bolder">
      {{ $t(`Calendar for ${ product.id ? product.name: '———' } —`) }}
      <span v-if="inventoryWarehouse">
        {{ inventoryWarehouse.label }}
        <span v-if="isSwitchToCalendarTable">
          {{ $t('(All Assets)') }}
        </span>
      </span>
      <span v-else>
        {{ $t('All Warehouses') }}
      </span>
    </h4>
  </portal>
</template>

<script>
export default {
  name: 'InventoryProductName',
  computed: {
    product() {
      return this.$store.state[this.MODULE_NAME].product
    },
    isSwitchToCalendarTable() {
      return this.$store.state[this.MODULE_NAME_ALLOCATIONS].isSwitchToCalendarTable
    },
    inventoryWarehouse() {
      const warehouses = this.$store.state.horizontalMenu.warehouseLocations
      const { selectedWarehouseId } = this.$store.state[this.CALENDAR_MODULE_NAME]
      return warehouses.find(({ id }) => id === selectedWarehouseId) || null
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const MODULE_NAME_ALLOCATIONS = 'allocations'
    const CALENDAR_MODULE_NAME = 'calendar'

    return {
      MODULE_NAME,
      MODULE_NAME_ALLOCATIONS,
      CALENDAR_MODULE_NAME,
    }
  },
}
</script>
