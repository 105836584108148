import { render, staticRenderFns } from "./InventoryFilterTabs.vue?vue&type=template&id=cd013420&scoped=true"
import script from "./InventoryFilterTabs.vue?vue&type=script&lang=js"
export * from "./InventoryFilterTabs.vue?vue&type=script&lang=js"
import style0 from "./InventoryFilterTabs.vue?vue&type=style&index=0&id=cd013420&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd013420",
  null
  
)

export default component.exports