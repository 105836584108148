<template>
  <b-overlay :show="isFetchingDayCalendar">
    <div class="mb-2">
      <b-table-simple
        v-bind="getTableProps()"
        bordered
        no-border-collapse
      >
        <b-thead>
          <b-tr>
            <b-th
              v-for="{key, label, thStyle} in tableColumns"
              :key="key"
              :style="{thStyle, textAlign: 'center'}"
            >
              {{ label }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody v-if="isFetchingDayCalendar" />
        <b-tbody
          v-else
          id="v-for-object"
        >
          <b-tr>
            <b-th class="tableHeader">
              {{ getFormatDay() }}
            </b-th>
            <b-th
              v-for="(item, index) in Array(10)"
              :key="index"
            >
              <span />
            </b-th>
          </b-tr>
          <b-tr
            v-for="(item, index) in dataCalendarDay"
            :key="index"
          >
            <b-th>{{ index }}</b-th>
            <tool-tip-calendar
              :is-popup="item.available.popup"
              :is-count="item.available.count "
              :count-title="'available'"
              :count-title-key="index"
            />
            <tool-tip-calendar
              :is-popup="item.in.popup"
              :is-count="item.in.count "
              :count-title="'in'"
              :count-title-key="index"
            />
            <tool-tip-calendar
              :is-popup="item.other_loc.popup"
              :is-count="item.other_loc.count "
              :count-title="'other_loc'"
              :count-title-key="index"
            />
            <tool-tip-calendar
              :is-popup="item.reserved.popup"
              :is-count="item.reserved.count "
              :count-title="'reserved'"
              :count-title-key="index"
            />
            <tool-tip-calendar
              :is-popup="item.out.popup"
              :is-count="item.out.count "
              :count-title="'out'"
              :count-title-key="index"
            />
            <tool-tip-calendar
              :is-popup="item.tin.popup"
              :is-count="item.tin.count "
              :count-title="'tin'"
              :count-title-key="index"
            />

            <tool-tip-calendar
              :is-popup="item.tout.popup"
              :is-count="item.tout.count"
              :count-title="'tout'"
              :count-title-key="index"
            />
            <tool-tip-calendar
              :is-popup="item.service.popup"
              :is-count="item.service.count"
              :count-title="'service'"
              :count-title-key="index"
            />
            <tool-tip-calendar
              :is-popup="item.on_order_po.popup"
              :is-count="item.on_order_po.count"
              :count-title="'on_order_po'"
              :count-title-key="index"
            />
            <tool-tip-calendar
              :is-popup="item.unavailable.popup"
              :is-count="item.unavailable.count"
              :count-title="'unavailable'"
              :count-title-key="index"
            />
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </b-overlay>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BOverlay,
} from 'bootstrap-vue'
import ToolTipCalendar
from '@/views/main/inventory/inventory-calendar-product/components/calendarTableAllAssets/toolTipCalendar/ToolTipCalendar.vue'
import * as dateFnsOptions from 'date-fns'
import config from './config'

export default {
  name: 'CalendarTableAllAssets',
  components: {
    ToolTipCalendar,
    BOverlay,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
  },
  props: {
    isFetchingDayCalendar: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      currentDay: new Date().getDay(),
    }
  },
  computed: {
    textAlign() {
      return textAlign
    },
    dataCalendarDay() {
      const { allocationByDay } = this.$store.state[this.MODULE_NAME]
      delete allocationByDay.data.available
      return allocationByDay.data
    },
    isCalendarOneDay() {
      return this.$store.state[this.CALENDAR_MODULE_NAME].selectedDate
    },
  },
  methods: {
    onClose() {
      this.$refs.tooltip.$emit('close')
    },
    getTableProps() {
      return {
        ref: 'CalendarTableAllAssets',
        responsive: true,
        'primary-key': 'id',
        'show-empty': true,
        'empty-text': this.$t('None found'),
        class: 'position-relative calendar-table-all-assets',
        striped: true,
        small: true,
      }
    },
    getFormatDay() {
      return dateFnsOptions.format(this.isCalendarOneDay, 'MMMM dd, yyyy')
    },
  },
  setup() {
    const { tableColumns } = config()
    const MODULE_NAME = 'allocations'
    const CALENDAR_MODULE_NAME = 'calendar'

    return {
      tableColumns,
      MODULE_NAME,
      CALENDAR_MODULE_NAME,
    }
  },
}
</script>
<style >
.tableHeader{
  background: #84B9AF40 !important;

}
</style>
