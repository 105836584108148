export default {
  SET_DATA(state, data) {
    state.date = data
  },
  START_DATE_OF_MONTH(state, data) {
    state.startOfMonth = data
  },
  SET_WAREHOUSE_ID(state, id) {
    state.selectedWarehouseId = id
  },
  SET_SELECTED_DATE(state, date) {
    state.selectedDate = date
  },
}
