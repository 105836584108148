<template>
  <div
    v-if="isSwitchToCalendarTable"
    class="d-flex align-items-center"
  >
    <div
      class="dayCalendary"
    >
      <p>{{ getFormatDay() }}</p>
    </div>
    <b-button
      class="p-0  ml-1 mr-1"
      variant="secondary"
      :disabled="isFetching"
      style="height: 36px; min-width: 36px"
      @click="prevDay"
    >
      <feather-icon
        v-b-tooltip.noninteractive.hover
        :title="$t('Prev day')"
        class="cursor-pointer"
        icon="LArrowUpLineIcon"
        size="16"
      />
    </b-button>
    <b-button
      class="p-0"
      variant="secondary"
      :disabled="isFetching"
      style="height: 36px; min-width: 36px"
      @click="nextDay"
    >
      <feather-icon
        v-b-tooltip.noninteractive.hover
        :title="$t('Next day')"
        class="cursor-pointer"
        icon="LArrowDownLineIcon"
        size="16"
      />
    </b-button>
    <b-button
      :disabled="isThisDayForDay || isFetching"
      variant="success"
      style="height: 36px; min-width: 80px"
      class="p-0 ml-1 mr-1"
      @click="setCurrentDate"
    >
      {{ $t('Today') }}
    </b-button>

    <l-data-picker
      :ref="'lDataPicker'"
      class="mb-0 bg-white"
      style="height: 36px; min-width: 160px;width:400px"
      name="calendar"
      :field="{
        label: '',
        options: {
          config: {
            'is-calendar-icon': true,
            'default-date': new Date()
          },
        }}"
      @input="getDataByCalendar"
    />
  </div>
  <div
    v-else
    class="d-flex align-items-center"
    style="gap: 12px"
  >
    <b-button
      class="p-0"
      variant="secondary"
      :disabled="isFetching"
      style="height: 36px; min-width: 36px"
      @click="prevMonth"
    >
      <feather-icon
        v-b-tooltip.noninteractive.hover
        :title="$t('Prev month')"
        class="cursor-pointer"
        icon="LArrowDownDoubleLineIconUp"
        size="16"
      />
    </b-button>
    <b-button
      class="p-0"
      variant="secondary"
      :disabled="isFetching"
      style="height: 36px; min-width: 36px"
      @click="prevWeek"
    >
      <feather-icon
        v-b-tooltip.noninteractive.hover
        :title="$t('Prev week')"
        class="cursor-pointer"
        icon="LArrowUpLineIcon"
        size="16"
      />
    </b-button>
    <b-button
      :disabled="isThisDay || isFetching"
      variant="success"
      style="height: 36px; min-width: 80px"
      class="p-0"
      @click="setCurrentDate"
    >
      {{ $t('Today') }}
    </b-button>
    <b-button
      class="p-0"
      variant="secondary"
      :disabled="isFetching"
      style="height: 36px; min-width: 36px"
      @click="nextWeek"
    >
      <feather-icon
        v-b-tooltip.noninteractive.hover
        :title="$t('Next week')"
        class="cursor-pointer"
        icon="LArrowDownLineIcon"
        size="16"
      />
    </b-button>
    <b-button
      class="p-0"
      variant="secondary"
      :disabled="isFetching"
      style="height: 36px; min-width: 36px"
      @click="nextMonth"
    >
      <feather-icon
        v-b-tooltip.noninteractive.hover
        :title="$t('Next month')"
        class="cursor-pointer"
        icon="LArrowDownDoubleLineIconDown"
        size="16"
      />
    </b-button>
    <l-data-picker
      :ref="'lDataPicker'"
      class="mb-0 bg-white"
      name="calendar"
      :field="{
        label: '',
        options: {
          config: {
            'is-calendar-icon': true,
            'defaultDate': new Date()
          }
        }}"
      @input="getDataByCalendar"
    />
  </div>

</template>

<script>
// Components =>
import {
  BButton, VBTooltip,
} from 'bootstrap-vue'
import LDataPicker from '@/views/components/DynamicForm/components/LFlatPicker.vue'
import * as dateFnsOptions from 'date-fns'

export default {
  name: 'InventoryDateController',
  components: {
    BButton,
    LDataPicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    prevMonth: {
      type: Function,
      required: true,
    },
    nextMonth: {
      type: Function,
      required: true,
    },
    prevWeek: {
      type: Function,
      required: true,
    },
    nextWeek: {
      type: Function,
      required: true,
    },
    prevDay: {
      type: Function,
      required: true,
    },
    nextDay: {
      type: Function,
      required: true,
    },
    setCurrentDate: {
      type: Function,
      required: true,
    },
    changeDate: {
      type: Function,
      required: true,
    },
    isThisDay: {
      type: Boolean,
      required: true,
    },
    isThisDayForDay: {
      type: Boolean,
      required: true,
    },
    isFetching: {
      type: Boolean,
      required: true,
    },
    selectedDate: {
      type: Date,
      required: true,
    },
  },
  computed: {
    isSwitchToCalendarTable() {
      return this.$store.state[this.MODULE_NAME].isSwitchToCalendarTable
    },
    isCalendarOneDay() {
      return this.$store.state[this.CALENDAR_MODULE_NAME].selectedDate
    },
  },
  methods: {
    allStatesDisBtn(bool) {
      this.$store.commit(`${this.MODULE_NAME}/ALL_STATES_DIS_BTN`, bool)
    },
    getDataByCalendar(calendarDate) {
      const date = new Date(calendarDate)
      this.allStatesDisBtn(false)
      this.changeDate(date)
    },
    getFormatDay() {
      return dateFnsOptions.format(this.isCalendarOneDay, 'MMMM dd, yyyy')
    },
  },
  setup() {
    const MODULE_NAME = 'allocations'
    const CALENDAR_MODULE_NAME = 'calendar'
    return {
      MODULE_NAME,
      CALENDAR_MODULE_NAME,
    }
  },
}
</script>
<style>
.dayCalendary{
  font-size: 24px;
  font-weight: 700;
  line-height: 27.28px;
  text-align: left;
  width:224px;
  color: #646464;
  display: flex;
  justify-content: flex-start;
  align-items: center;

}
</style>
