<template>
  <div>
    <div
      class="d-flex justify-content-center"
      style="margin-bottom: 7px"
    >
      <b-skeleton
        width="30%"
        height="24px"
        class="mb-0"
      />
    </div>
    <div class="d-flex justify-content-between mb-2">
      <div
        class="d-flex"
        style="gap: 16px"
      >
        <b-button
          variant="secondary-light"
          disabled
        >
          <feather-icon
            class="cursor-pointer"
            icon="LArrowUpLineIcon"
            size="16"
          />
        </b-button>
        <b-button
          variant="secondary-light"
          disabled
        >
          <feather-icon
            :title="$t('Next')"
            class="cursor-pointer"
            icon="LArrowDownLineIcon"
            size="16"
          />
        </b-button>
        <b-button
          disabled
          variant="success"
        >
          {{ $t('Today') }}
        </b-button>
      </div>
      <div
        class="d-flex align-items-center"
        style="gap: 16px"
      >
        <b-dropdown
          size="md"
          no-caret
          variant="secondary-light"
          class="switch-btn"
          disabled
        >
          <template
            #button-content
          >
            <div class="dropNewClass">
              <span>
                {{ $t('Switch to') }}
              </span>
              <feather-icon
                icon="LArrowDownIcon"
                size="11"
                class="lightIcon m-0"
              />
            </div>
          </template>
        </b-dropdown>
      </div>
    </div>
    <b-row class="justify-content-between mb-2">
      <b-col
        cols="12"
        class="d-flex"
      >
        <b-button
          style="min-width: 130px;"
          disabled
          variant="success"
          class="ml-auto"
        >
          {{ $t('All Assets') }}
        </b-button>
      </b-col>
    </b-row>
    <b-card
      class="l-calendar__card py-2"
      no-body
    >
      Loading...
    </b-card>
  </div>
</template>

<script>
import {
  BSkeleton, BButton, BDropdown, BRow, BCol, BCard,
} from 'bootstrap-vue'

export default {
  name: 'InventoryTableSkeleton',
  components: {
    BCol,
    BRow,
    BCard,
    BButton,
    BSkeleton,
    BDropdown,
  },
  data() {
    return {
      weekDays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'],
    }
  },
}
</script>
<style lang="scss">
.l-calendar--skeleton{
  height: 280px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  &__container{
    display: inline-grid;
    grid-template-columns: auto auto auto auto auto;
  }
  &__box{
    width: 67px;
    height: 56px;
  }
}
</style>
