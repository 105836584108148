<template>
  <div>
    <b-row>
      <b-col cols="6">
        <div class="inventory-read-only-data py-1 px-2">
          <div
            class="d-flex"
            style="gap: 24px"
          >
            <span
              class="font-weight-bolder d-inline-block"
              style="min-width: 140px"
            >
              SKU
            </span>
            <span>{{ product.sku ? product.sku : '---' }}</span>
          </div>
        </div>
      </b-col>
      <b-col cols="6">
        <div class="inventory-read-only-data py-1 px-2">
          <div v-if="selectedWarehouseId">
            <div
              class="d-flex"
              style="gap: 24px"
            >
              <span
                class="font-weight-bolder d-inline-block"
                style="min-width: 140px"
              >
                Stock
              </span>
              <span>{{ warehouseCounts.stock }}</span>
            </div>
            <div
              class="d-flex"
              style="gap: 24px"
            >
              <span
                class="font-weight-bolder d-inline-block"
                style="min-width: 140px"
              >
                Stock Other Loc.
              </span>
              <span>{{ warehouseCounts.stock_other }}</span>
            </div>
          </div>
          <div v-else>
            <div
              class="d-flex"
              style="gap: 24px"
            >
              <span
                class="font-weight-bolder d-inline-block"
                style="min-width: 140px"
              >
                All stocks
              </span>
              <span>{{ allStocks }}</span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BRow, BCol } from 'bootstrap-vue'

export default {
  name: 'InventoryReadOnlyData',
  components: {
    BRow,
    BCol,
  },
  computed: {
    product() {
      return this.$store.state[this.PRODUCT_MODULE_NAME].product
    },
    warehouseCounts() {
      const { allocation, allocationByDay, isSwitchToCalendarTable } = this.$store.state[this.MODULE_NAME]
      const data = isSwitchToCalendarTable ? allocationByDay : allocation
      return data?.warehouse_counts || {
        stock: '---',
        stock_other: '---',
      }
    },
    allStocks() {
      const { warehouse_counts } = this.$store.state[this.MODULE_NAME].allocation
      return warehouse_counts?.stock + warehouse_counts?.stock_other
    },
    selectedWarehouseId() {
      return this.$store.state[this.CALENDAR_MODULE_NAME].selectedWarehouseId
    },
  },
  setup() {
    const MODULE_NAME = 'allocations'
    const PRODUCT_MODULE_NAME = 'inventories'
    const CALENDAR_MODULE_NAME = 'calendar'

    return {
      MODULE_NAME,
      PRODUCT_MODULE_NAME,
      CALENDAR_MODULE_NAME,
    }
  },
}
</script>

<style lang="scss" scoped>
.inventory-read-only-data {
  background-color: #fff;
  min-height: 80px;
  border-radius: 5px;
}
</style>
